import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'

import {HtmlContent} from './html-content'

export function StaticPage() {
  const params = ReactRouterDom.useParams()
  const page = R.head(params)

  const translate = Herz.I18n.useTranslate(
    () => [`${page}.title`, `${page}.content`],
    [page]
  )

  return (
    <>
      <ThemeUi.Box
        sx={{
          position: 'absolute',
          backgroundImage:
            'url(/images/background-pattern-d-shape.svg), linear-gradient(180deg, rgba(0, 87, 255, 0) 0%, #17061D 100%), linear-gradient(180deg, #015b9a 0%, rgba(23, 6, 29, 0) 100%)',
          backgroundSize: '5000px, cover, cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: '100%',
          left: '0px',
          top: '0px',
        }}
      />
      <ThemeUi.Box
        as="section"
        sx={{
          position: 'relative',
          flex: 1,
          mx: 'auto',
          maxWidth: '1000px',
          width: '100%',
          px: 1,
          pb: 4,
        }}
      >
        <ThemeUi.Heading
          as="h1"
          sx={{
            py: [3, 4],
            fontSize: [4, 5],
          }}
        >
          {translate(`${page}.title`)}
        </ThemeUi.Heading>
        <ThemeUi.Box
          sx={{
            backgroundColor: 'background',
            width: '100%',
            p: [2, 4],
            borderRadius: 1,
          }}
        >
          <HtmlContent>{translate(`${page}.content`)}</HtmlContent>
        </ThemeUi.Box>
      </ThemeUi.Box>
    </>
  )
}

// For @loadable/components
export default StaticPage
